import Link from "next/link";
import {ButtonProps} from "./BlackButton";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from "react";
import {handleLog} from "../logger.helper";

const WhiteOutlined: React.FC<ButtonProps> = ({href, text, hoverUp = true, className, faIcon}) => {
    return (
        <>
            <div onClick={() => handleLog("Clickrate_DienstenViaHome", "Home", "Clickrate_DienstenViaHome")}
                 className={hoverUp ? "hover-up w-100" : ''}>
                <Link href={href} legacyBehavior>
                    <a className={`btn btn-default btn-bd-green-hover btn-select mx-0 mb-20 font-1-8em font-sm-1-2em w-100 ${className}`}>
                        {faIcon && <><FontAwesomeIcon icon={faIcon} className='font-2em'/> {" "}</>}
                        <br/>
                        <div className='mt-15 text-break'>
                            {text}
                        </div>
                    </a>
                </Link>
            </div>
        </>
    );
};

export default WhiteOutlined;
