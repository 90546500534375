import React from "react";
import Section from "../layout/Section";
import Title from "../layout/Title";
import TextButton from "../shared/TextButton";
import SneakVacatureCard from "./SneakVacatureCard";
import {useGlobalContext} from "../../providers/global.provider";
import {handleLog} from "../logger.helper";

const MAX_VACATURES: number = 4;

const SneakPeakVacatures: React.FC = () => {
    const {isLoading, error, vacatures} = useGlobalContext();

    return (
        <>
            <Section className='section-box section-green bg-light-green pt-50 pb-50'>
                <Title title='Vacatures in de kijker'/>
                <div className="container">
                    <div className="row">
                        {isLoading ? (
                            <div className="text-center">
                                <div className="spinner-border" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                            </div>
                        ) : error ? (
                            <div className="alert alert-danger" role="alert">
                                {error}
                            </div>
                        ) : (
                            vacatures.slice(0, MAX_VACATURES).map((item) => (
                                <SneakVacatureCard item={item} key={item.id}/>
                            ))
                        )}
                        {!isLoading && <div className='col-12 text-center'>
                            <span
                                onClick={() => handleLog("Clickrate_VacaturesBekijkAllesHome", "Home", "Clickrate_VacaturesBekijkAllesHome")}>
                            <TextButton href='/Vacatures' text='Bekijk alle vacatures'/>
                                </span>
                        </div>}
                    </div>
                </div>
            </Section>
        </>
    );
};

export default SneakPeakVacatures;
