import Section from "../layout/Section";
import Title from "../layout/Title";
import WhiteOutlined from "../shared/WhiteOutlined";
import {faChalkboardUser, faCompass, faMagnifyingGlass, faPuzzlePiece} from "@fortawesome/free-solid-svg-icons";
import React from "react";
import Link from "next/link";

const Aanbod: React.FC = () => {
    return (
        <>
            <Section>
                <Title
                    title='Diensten'
                />
                <div className="container">
                    <div className="text-center">
                        <p className="text-inter-lg color-gray-600">
                            Wij combineren graag ons enthousiasme, ondernemingszin en expertise
                            om <strong>impact</strong> en <strong>innovatie</strong> te creëren in uw projecten.
                        </p>
                        <br/>
                        <p className="text-inter-lg color-gray-600">
                            Dankzij ons <Link href="/OverOns"><strong>ondernemersinstinct</strong></Link> blijven we
                            goed geconnecteerd met de snel
                            evoluerende
                            technologische wereld. We zijn altijd op zoek naar nieuwe kansen, innovatieve ideeën en
                            baanbrekende technologieën die ons bedrijf kunnen versterken.
                        </p>
                        <br/>
                        <p className="text-inter-lg color-gray-600">
                            Daarnaast begrijpen we het belang van <strong>samenwerking en partnerschappen</strong>. We
                            streven ernaar om synergieën te creëren met andere bedrijven en professionals die
                            complementaire vaardigheden en expertise bieden. Door deze samenwerkingen vergroten we ons
                            netwerk en vergaren we waardevolle expertise en inzichten.
                        </p>
                        <div className='row mt-30'>
                            <div className='col-lg-3 col-xl-3 col-md-6 col-sm-6 col-6'>
                                <WhiteOutlined faIcon={faPuzzlePiece} href="/Diensten#diensten-aanbod"
                                               text="Projectsourcing"/>
                            </div>
                            <div className='col-lg-3 col-xl-3 col-md-6 col-sm-6 col-6'>
                                <WhiteOutlined faIcon={faMagnifyingGlass} href="/Diensten#diensten-aanbod"
                                               text="Recruitment"/>
                            </div>
                            <div className='col-lg-3 col-xl-3 col-md-6 col-sm-6 col-6'>
                                <WhiteOutlined faIcon={faChalkboardUser} href="/Diensten#diensten-aanbod"
                                               text="Training"/>
                            </div>
                            <div className='col-lg-3 col-xl-3 col-md-6 col-sm-6 col-6'>
                                <WhiteOutlined faIcon={faCompass} href="/Diensten#diensten-aanbod"
                                               text="Begeleiding"/>
                            </div>
                        </div>
                    </div>
                </div>
            </Section>
        </>
    );
};

export default Aanbod;
