import React from 'react';
import Section from '../layout/Section';
import Title from '../layout/Title';
import ContactForm from "../shared/ContactForm";

const HomeForm: React.FC = () => {
    return (
        <>
            <Section className="bg-gray-100 pt-50 pb-50">
                <Title title="Contact"/>
                <div className="container">
                    <div className="icon-wave box-gray-100">
                        <div className="row d-flex justify-content-center">
                            <div className="col-lg-8 col-12">
                                <ContactForm/>
                            </div>
                        </div>
                    </div>
                </div>
            </Section>
        </>
    );
};

export default HomeForm;
