import Section from "../layout/Section";
import WhiteButton from "../shared/WhiteButton";
import {faCoffee} from "@fortawesome/free-solid-svg-icons";
import React from "react";
import Image from "next/image";

const BecomeCohesian: React.FC = () => {
    return (
        <>
            <Section>
                <div className="container">
                    <div className="box-green pl-50 pr-50 pt-80 pb-80 p-sm-15 br-16">
                        <div className='row'>
                            <div className='col-lg-8 col-md-12 col-sm-12 col-12'>
                                <h3 className="text-heading-2 color-white text-lg-start text-center">
                                    Let&apos;s have a coffee
                                </h3>
                                <p className="text-inter-lg text-desc-white text-lg-start text-center mt-30">
                                    Spreekt ons verhaal jou aan? Of je nu deel wilt uitmaken van ons team, wilt
                                    samenwerken of gewoon geïnteresseerd
                                    bent om meer te weten te komen over ons...
                                </p>
                                <br/>
                                <p className="text-inter-lg text-desc-white text-lg-start text-center">
                                    Geef een seintje, want wij houden van koffie en wij houden van netwerken!
                                </p>
                                <div className='mt-30 text-lg-start text-center'>
                                    <WhiteButton href='/Contact' faIcon={faCoffee} text="Let&apos;s have a coffee"/>
                                </div>
                            </div>
                            <div className='col-lg-4 col-md-12 col-sm-12 col-12 mt-md-30 mt-sm-30 px-lg-5 pt-25'>
                                <Image
                                    fill={true}
                                    className="bdrd-16 h-auto position-relative"
                                    src="/assets/imgs/paginas/home/coffee.webp" alt="Agon"/>
                            </div>
                        </div>
                    </div>
                </div>
            </Section>
        </>
    );
};

export default BecomeCohesian;
