import Link from "next/link";
import Layout from "../components/layout/Layout";
import Aanbod from "../components/home/Aanbod";
import OverOns from "../components/shared/OverOns";
import SneakPeakVacatures from "../components/home/SneakPeakVacatures";
import BecomeCohesian from "../components/home/BecomeCohesian";
import Sectors from "../components/shared/Sectors";
import HomeForm from "../components/home/HomeForm";
import {faChevronRight} from "@fortawesome/free-solid-svg-icons/faChevronRight";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Image from "next/image";
import Head from "next/head";
import React from "react";
import {handleLog} from "../components/logger.helper";

function PageHome() {

    return (
        <>
            <Head>
                <link rel="manifest" href="/manifest.webmanifest"/>
                <link rel="apple-touch-icon" href="/assets/imgs/app/apple-touch-icon.png"/>
                <link
                    rel="apple-touch-icon"
                    sizes="48x48"
                    href="/assets/imgs/app/apple-touch-icon-48x48.png"
                />
                <link
                    rel="apple-touch-icon"
                    sizes="57x57"
                    href="/assets/imgs/app/apple-touch-icon-57x57.png"
                />
                <link
                    rel="apple-touch-icon"
                    sizes="72x72"
                    href="/assets/imgs/app/apple-touch-icon-72x72.png"
                />
                <link
                    rel="apple-touch-icon"
                    sizes="76x76"
                    href="/assets/imgs/app/apple-touch-icon-76x76.png"
                />
                <link
                    rel="apple-touch-icon"
                    sizes="114x114"
                    href="/assets/imgs/app/apple-touch-icon-114x114.png"
                />
                <link
                    rel="apple-touch-icon"
                    sizes="120x120"
                    href="/assets/imgs/app/apple-touch-icon-120x120.png"
                />
                <link
                    rel="apple-touch-icon"
                    sizes="144x144"
                    href="/assets/imgs/app/apple-touch-icon-144x144.png"
                />
                <link
                    rel="apple-touch-icon"
                    sizes="152x152"
                    href="/assets/imgs/app/apple-touch-icon-152x152.png"
                />
                <link
                    rel="apple-touch-icon"
                    sizes="180x180"
                    href="/assets/imgs/app/apple-touch-icon-180x180.png"
                />
                <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5"/>
                <meta charSet="utf-8"/>
                <meta name="msapplication-TileColor" content="#ffffff"/>
                <meta name="msapplication-TileImage" content="/assets/imgs/ms-icon-144x144.png"/>
                <meta httpEquiv="X-UA-Compatible" content="IE=edge"/>
                <meta name="viewport" content="width=device-width, initial-scale=1"/>
                <meta name="author" content="Cohesian"/>
                <meta name="theme-color" content="#ffffff"/>
                <meta name="description"
                      content="Engineering partner ✓ Projectsourcing, recruitment, begeleiding en advies, training ✓ Consultancy voor ondernemende technische profielen ✓ Fun at work ✓"/>
                <meta property="og:title" content="Cohesian Engineering"/>
                <meta property="og:description"
                      content="Visit our website for updates and information about Cohesian Engineering."/>
                <meta property="og:url" content="https://www.cohesian.be/"/>
                <meta name="keywords"
                      content="Engineering, Consultancy/consulting, Entrepreneurship, Entrepreneur, Ondernemer, Ondernemerschap, Technische profielen, Ingenieur, Innovatie, Fun, Connectie, Connection, Excellence, Excellentie, Ingenieursbureau, Partner, Persoonlijke ontwikkeling, Projectsourcing, Recruitment, Rekrutering, Training, Begeleiding, advies, Vacature, Jobs, Werk, Functie, Fun factor, Koffie, Netwerken, Maakindustrie, Automotive, Farmaceutica, Cosmetica"/>
                <title>Cohesian | Engineering jobs voor ondernemers</title>
            </Head>
            <Layout>
                <section className="section-box">
                    <div className="banner-hero banner-2">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-7 mt-50 animate__animated animate__fadeIn">
                                    <span
                                        onClick={() => handleLog("Clickrate_EngConsByEntr", "Home", "Clickrate_EngConsByEntr")}
                                        className="tag-1 bg-green-900">Engineering consulting by entrepreneurs</span>
                                    <h1 className="text-display-2 color-green-900 mt-30">
                                        Connection
                                        <br/>
                                        <span className='text-display-3 color-gray-900'>creates excellence</span>
                                    </h1>
                                    <p className="text-inter-lg color-gray-500 mt-40 pr-40">
                                        Bij Cohesian staan persoonlijke ontwikkeling en fun centraal. Wij geloven erin
                                        dat de verbondenheid binnen ons team en onze sterke connectie met de
                                        maatschappij, ons voortstuwen om excellentie te benaderen en voor technologische
                                        innovatie te zorgen.
                                    </p>
                                    <div className="mt-40">
                                        <Link href="/Spontaan" legacyBehavior>
                                            <a className="header-join-button btn btn-black">
                                                Join cohesian <FontAwesomeIcon
                                                className='font-xs' icon={faChevronRight}/>
                                            </a>
                                        </Link>
                                    </div>
                                </div>
                                <div className="col-lg-5 d-none d-lg-block animate__animated animate__fadeIn">
                                    <div className="banner-imgs text-center">
                                        <Image
                                            priority={true}
                                            width={400}
                                            height={490}
                                            className="position-relative"
                                            alt="5 personen kijken naar laptop"
                                            src="/assets/imgs/paginas/home/header.webp"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Aanbod/>
                <SneakPeakVacatures/>
                <OverOns/>
                <BecomeCohesian/>
                <Sectors/>
                <HomeForm/>
            </Layout>
        </>
    )
}

export default PageHome;