import Section from "../layout/Section";
import WhiteButton from "./WhiteButton";
import React from "react";
import Image from "next/image";
import Link from "next/link";

interface OverOnsBlockProps {
    withButton?: boolean;
}

const OverOns: React.FC<OverOnsBlockProps> = ({withButton = true}) => {
    return (
        <>
            <Section>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-sm-12 col-12">
                            <h2 className="text-heading-2 text-lg-start text-center">
                                Jouw verhaal is ons verhaal
                            </h2>
                            <p className="text-inter-lg color-gray-600  text-lg-start text-center mt-30">
                                <strong>Sluit je aan bij ons team en maak deel uit van ons avontuur, want..</strong>
                            </p>
                            <br/>
                            <p className="text-inter-lg color-gray-600 text-lg-start text-center">
                                Bij Cohesian investeren we in jouw{" "}<span
                                className='font-bold'>persoonlijke ontwikkeling</span>{" "}en
                                doelstellingen. Je krijgt de kans om te leren van experten in hun vakgebied via
                                workshops, opleidingen en veel meer.
                            </p>
                            <br/>
                            <p className="text-inter-lg color-gray-600 text-lg-start text-center">
                                Wij brengen het beste in jouw naar boven door verschillende teamactiviteiten en het
                                boosten van de{" "}<Link href={"/OverOns#funAtWork"}><span className='font-bold'>Fun Factor</span></Link>.
                            </p>
                            <br/>
                            <p className="text-inter-lg color-gray-600 text-lg-start text-center">
                                Ons doel is om een succesformule te creëren, waarmee jij door jouw ideeën en{" "}<span
                                className='font-bold'>innovaties</span>,
                                mee bijdraagt aan een{" "}<span className='font-bold'>betere samenleving</span>.
                            </p>
                            <div className="mt-30 text-lg-start text-center">
                                {withButton && (
                                    <WhiteButton href="OverOns" text="Meer over ons"/>
                                )}
                            </div>
                        </div>
                        <div className="col-lg-6 col-sm-12 col-12 text-lg-end text-center">
                            <Image className="br-16 position-relative h-auto mt-30 mt-lg-0"
                                   fill={true}
                                   src="/assets/imgs/paginas/home/meisje.webp"
                                   alt="Een meisje die juicht"/>
                        </div>
                    </div>
                </div>
            </Section>
        </>
    );
};

export default OverOns;
