import Section from "../layout/Section";
import Title from "../layout/Title";
import React from "react";
import Image from "next/image";
import {handleLog} from "../logger.helper";

const Sectors: React.FC = () => {
    return (
        <>
            <Section>
                <Title title="Sectoren"/>
                <div className="container"
                     onClick={() => handleLog("Clickrate_Sectoren", "Home/Diensten", "Clickrate_Sectoren")}>
                    <div className="row">
                        <div className="col-lg-3 col-6 pr-30">
                            <div className="card-grid-style-4 text-lg-start text-center">
                                <div className="grid-4-img color-bg-1">
                                    {/*<Link href='/Diensten'>*/}
                                    <Image width={288} height={0} className="h-auto"
                                           src="/assets/imgs/paginas/home/maakindustrie.webp" alt="Productie lijn"/>
                                    {/*</Link>*/}
                                </div>
                                <h1 className="text-heading-4 text-lg-start text-center">Maakindustrie</h1>
                                {/*<TextButton className='p-0' href='/Diensten' text='Ontdek meer'/>*/}
                            </div>
                        </div>
                        <div className="col-lg-3 col-6 pr-30">
                            <div className="card-grid-style-4 text-lg-start text-center">
                                <div className="grid-4-img color-bg-2">
                                    {/*<Link href='/Diensten'>*/}
                                    <Image width={288} height={0} className="h-auto"
                                           src="/assets/imgs/paginas/home/automotive.webp"
                                           alt="Constructie van autos"/>
                                    {/*</Link>*/}
                                </div>
                                <h1 className="text-heading-4 text-lg-start text-center">Automotive</h1>
                                {/*<TextButton className='p-0' href='/Diensten' text='Ontdek meer'/>*/}
                            </div>
                        </div>
                        <div className="col-lg-3 col-6 pr-30">
                            <div className="card-grid-style-4 text-lg-start text-center">
                                <div className="grid-4-img color-bg-3">
                                    {/*<Link href='/Diensten'>*/}
                                    <Image width={288} height={0} className="h-auto"
                                           src="/assets/imgs/paginas/home/ict.webp" alt="Programmeren"/>
                                    {/*</Link>*/}
                                </div>
                                <h1 className="text-heading-4 text-lg-start text-center">ICT</h1>
                                {/*<TextButton className='p-0' href='/Diensten' text='Ontdek meer'/>*/}
                            </div>
                        </div>
                        <div className="col-lg-3 col-6 pr-30">
                            <div className="card-grid-style-4 text-lg-start text-center">
                                <div className="grid-4-img color-bg-4">
                                    {/*<Link href='/Diensten'>*/}
                                    <Image width={288} height={0} className="h-auto"
                                           src="/assets/imgs/paginas/home/farmaceutica.webp"
                                           alt="Iemand die pipetteert"/>
                                    {/*</Link>*/}
                                </div>
                                <h1 className="text-heading-4 text-lg-start text-center">Farmaceutica & Cosmetica</h1>
                                {/*<TextButton className='p-0' href='/Diensten' text='Ontdek meer'/>*/}
                            </div>
                        </div>
                    </div>
                </div>
            </Section>
        </>
    );
};

export default Sectors;
