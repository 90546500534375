import React from 'react';

interface TitleProps {
    title: string;
    subtitle?: string;
    children?: React.ReactNode;
    id?: string;
}

const Title: React.FC<TitleProps> = ({title, subtitle, children, id}) => {
    return (
        <>
            <div id={id} className="container mb-lg-5 mb-40">
                <div className="row">
                    <div className="col-lg-2 col-sm-1 col-12"/>
                    <div className="col-lg-8 col-sm-10 col-12 text-center">
                        <h2 className="text-heading-2 color-gray-900">
                            {title}
                        </h2>
                        {subtitle && <p className="text-inter-lg color-gray-600 mt-30">
                            {subtitle}
                        </p>}
                        {children}
                    </div>
                </div>
            </div>
        </>
    );
};

export default Title;
