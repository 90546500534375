import Link from "next/link";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBriefcase, faLocationDot, faUserCircle} from "@fortawesome/free-solid-svg-icons";
import {VacatureModel} from "../../pages/api/vacature.model";
import React from "react";
import {faMinus} from "@fortawesome/free-solid-svg-icons/faMinus";
import {formatDate} from "../vacatures/VacatureCard";
import {handleLog} from "../logger.helper";
import {faChevronRight} from "@fortawesome/free-solid-svg-icons/faChevronRight";

interface VacatureCardProps {
    item: VacatureModel;
}

const SneakVacatureCard: React.FC<VacatureCardProps> = ({item}) => {
    return (
        <>
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                <div className="product-item-2">
                    <div className="product-info">
                        {/*<p className="color-gray-600">*/}
                        {/*    {formatDate(item.publish_date)}*/}
                        {/*</p>*/}
                        <Link
                            href={{
                                pathname: '/Vacature',
                                query: {
                                    data: JSON.stringify(item)
                                }
                            }}
                            as={`/Vacature?id=${item.id}`}
                            passHref legacyBehavior>
                            <h3 onClick={() => handleLog("Clickrate_VacatureViaHome", "Home", "Clickrate_VacatureViaHome")}
                                className="text-body-lead font-2-4em color-gray-900 pointer">{item.title}</h3>
                        </Link>
                        {/*<p className="text-body-excerpt color-gray-600 vacature-short mt-15">*/}
                        {/*    {item?.description}*/}
                        {/*</p>*/}
                        <div className='mt-30'>
                            <div className='font-sm text-bold color-gray-600 d-flex align-items-center'>
                                <FontAwesomeIcon className='mr-5' icon={faUserCircle}/>
                                {item?.vakgebied}
                                <FontAwesomeIcon className='font-xs mx-2' icon={faMinus}/>
                                <FontAwesomeIcon className='mr-5' icon={faBriefcase}/>
                                {item?.sector}
                                <FontAwesomeIcon className='font-xs mx-2' icon={faMinus}/>
                                <FontAwesomeIcon className='mr-5' icon={faLocationDot}/>
                                {item?.location}
                            </div>
                            <div className='d-flex justify-content-end mt-15'>
                                <Link
                                    href={{
                                        pathname: '/Vacature',
                                        query: {
                                            data: JSON.stringify(item)
                                        }
                                    }}
                                    as={`/Vacature?id=${item.id}`}
                                    passHref legacyBehavior>
                                    <a onClick={() => handleLog("Clickrate_VacatureViaHome", "Home", "Clickrate_VacatureViaHome")}
                                       className='font-xl'>
                                        Ontdek <FontAwesomeIcon className="font-xs"
                                                                    icon={faChevronRight}/>
                                    </a>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default SneakVacatureCard;
